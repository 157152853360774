
/*==== Import Components ====*/
import LoginComp from '../components/pages/auth/Login_Comp/Login_Comp';
import React from "react";



const Auth = () => {
  return (
    <main>

        {/*==== Login Component ====*/}
        <LoginComp />

    </main>
  )
}

export default Auth;